import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-parent.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <h2 {...{
      "id": "a-how-does-the-school-help-students-balance-their-rights-with-their-responsibilities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-how-does-the-school-help-students-balance-their-rights-with-their-responsibilities",
        "aria-label": "a how does the school help students balance their rights with their responsibilities permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A. How Does The School Help Students Balance Their Rights With Their Responsibilities?`}</h2>
    <p>{`PSI expects students to work towards their full academic potential and behave in a manner that will bring respect and honour to the School, their families, their own communities and their personal development as responsible learners.`}</p>
    <p>{`It is our aim to nurture well-balanced, mature individuals who are able to take responsibility for their own actions and who will grow to be valued members of the international community. Students at PSI are encouraged to recognise that rights bring with them equivalent responsibilities. Student rights are balanced against responsibilities in the following ways:`}</p>
    <table><colgroup><col style={{
          "width": "14%"
        }} /><col style={{
          "width": "40%"
        }} /><col style={{
          "width": "44%"
        }} /></colgroup><thead><tr className="header"><th></th><th><strong>Students have the right to</strong></th><th><strong>Students have the responsibility to</strong></th></tr></thead><tbody><tr className="odd"><td><strong>Learning</strong></td><td>Learn to the best of their ability in well-taught, productive classes</td><td>Do their best work and help others do their best. This includes listening and following directions, asking for explanations when needed, disturbing no one in class, arriving to class on time, and turning in neat, carefully planned and completed work</td></tr><tr className="even"><td><strong>Respect</strong></td><td>Be happy and to be treated with understanding and respect as individuals</td><td>Respect others, treat them with understanding and not hurt their feelings</td></tr><tr className="odd"><td><strong>Expression</strong></td><td>Hear and be heard and be free to express their opinions, feelings and ideas</td><td>Respect others’ opinions, feelings and ideas and to listen to others</td></tr><tr className="even"><td><strong>Safety</strong></td><td>Be safe and secure in school with rules developed to support these rights</td><td>Respect others’ right to safety, treat others with the same kindness they would expect themselves, and obey school rules</td></tr><tr className="odd"><td><strong>Property</strong></td><td>Bring their belongings and materials to school and utilise school materials</td><td>Look after their own possessions, respect other people’s property, and use and share school materials in an appropriate manner</td></tr><tr className="even"><td><strong>Dress Code</strong></td><td>Wear clothes of their choice to school without being judged by peers</td><td><p>Wear clothes that are decent, inoffensive, and appropriate and take care of their clothing</p><p>Refrain from judging the clothing of others</p></td></tr></tbody></table>
    <h2 {...{
      "id": "b-how-do-the-homeroom-and-advisory-programmes-help-students-make-good-choices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#b-how-do-the-homeroom-and-advisory-programmes-help-students-make-good-choices",
        "aria-label": "b how do the homeroom and advisory programmes help students make good choices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B. How Do The Homeroom And Advisory Programmes Help Students Make Good Choices?`}</h2>
    <p>{`All students in Grades 6 - 10 have 40-minutes per week of homeroom time, which is used to offer our Personal and Social Education (PSE) programme. Students learn about and discuss important issues that are age appropriate like bullying, social relationships, dating, stress management and drug / alcohol abuse. Some of these sessions are led by homeroom teachers, others by the counsellors. In sensitive issues like reproduction and sexuality, classes are split by gender to allow for more open discussions, and Science teachers may be included. These units are all designed to help students make safe, healthy and ethical choices.`}</p>
    <p>{`In addition, all students in Grades 6 - 12 have a 20-minute Advisory each day. These are designed for smaller groups of students to work closely with a teacher / advisor, who checks on student progress, addresses academic or behavioural issues and serves as a link between the School and home.`}</p>
    <h2 {...{
      "id": "c-what-is-the-code-of-conduct",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#c-what-is-the-code-of-conduct",
        "aria-label": "c what is the code of conduct permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`C. What Is The Code Of Conduct?`}</h2>
    <p>{`The school leadership, board and staff at PSI expect that all members of the school community demonstrate respect for self, others and the environment at all times. This includes any time that they are on campus and any time that they are away on school-sponsored trips. When students fail to do so, the school leadership and board reserve the right to take appropriate disciplinary action for activities that are considered detrimental to the welfare of the school, student body or individual.`}</p>
    <p>{`PSI students are subject to disciplinary action for incidents of misconduct occurring during school or while attending school-sponsored activities, whether on the school campus or off the campus. These rules include but are not limited to the following:`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Academic Integrity`}</em></strong>{` - All students are expected to maintain academic integrity with respect to their academic work. It is unacceptable to submit someone else's work as their own work, give their work to someone else to use, copy homework, or to use notes, signal or talk during a test. It is also unacceptable to use cell phones or other devices to obtain or share information during a test. It is important to note that students who help someone else cheat are held to the same standard and the same set of consequences.`}</p>
    <p>{`A complete explanation of the definitions, the roles and responsibilities for all parties, the consequences and the support systems may be found in the `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1Cgf02NVk3IufYMO4d2t5x_R30K7RKt8DlDPJwqy4Q9k/edit"
      }}>{` Secondary Academic Integrity Policy and Protocols `}</a>{`.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Alcohol, Drugs or Tobacco`}</em></strong>{` -`}</p>
    <p>{`The Board has a very clear policy on banned substances:`}</p>
    <p>{`PSI is a smoking (including e-cigarettes / vaping) and drug free zone with regards to the campus and all school sponsored events. No person may be on campus in a state of impairment.`}</p>
    <p>{`Students and staff found in violation of this policy are subject to disciplinary action, including possible expulsion / dismissal. As part of this process, the School works with students and their families to provide education and support where appropriate.`}</p>
    <p>{`The director may request parents to provide evidence of testing of their child for banned substances.`}</p>
    <p>{`At the director’s discretion, any person on campus may be removed if they present a clear and present danger.`}</p>
    <p>{`Specifically, that means the following for students and parents at the School:`}</p>
    <h5 {...{
      "id": "smoking--vaping",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#smoking--vaping",
        "aria-label": "smoking  vaping permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smoking / Vaping`}</h5>
    <p>{`Students found smoking / vaping on campus or in possession of associated paraphernalia are subject to disciplinary measures. Community members smoking or vaping on campus or at a school event that includes children are seen as in violation of the Parent Code of Conduct.`}</p>
    <h5 {...{
      "id": "alcohol",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#alcohol",
        "aria-label": "alcohol permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alcohol`}</h5>
    <p>{`Students on campus, and those participating in activities after school and trips outside school are not permitted to consume alcoholic drinks. The possession, transport or consumption of alcoholic drinks, or being in a state of alcohol impairment, either in school or on school-sponsored trips, results in suspension or expulsion. Limited consumption of alcohol on the premises by adults in conjunction with official school functions is permitted only with the prior approval of the school director.`}</p>
    <h5 {...{
      "id": "substance-abuse",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#substance-abuse",
        "aria-label": "substance abuse permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Substance Abuse`}</h5>
    <p>{`The following are viewed as examples of gross misconduct leading to disciplinary action that may result in expulsion:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The possession, distribution, sale and use of illicit drugs or associated paraphernalia`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Improper use or distribution of other substances or prescription medications`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Failure to cooperate with a reasonable request to test`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Being in a state of impairment during school time or at a school event due to substance abuse`}</p>
      </li>
    </ul>
    <p>{`Prescribed or ‘over the counter’ medicines that could affect a student’s performance or judgment must be declared to and discussed with the school doctor prior to any use at school. Failure to do so is a violation of this policy.`}</p>
    <p>{`Students caught distributing or selling any of these items are automatically referred to the Board Expulsion Committee.`}</p>
    <h5 {...{
      "id": "testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#testing",
        "aria-label": "testing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing`}</h5>
    <p>{`If the director has reasonable suspicion that a student is in a state of impairment, they request that the family provide evidence of testing by a qualified medical representative.`}</p>
    <p>{`Any student who tests positive for the presence of prohibited substances is in violation of this policy and is subject to discipline, up to and including expulsion.`}</p>
    <p>{`A student whose parents refuse to allow the student to take any drug and / or alcohol test is suspended pending a decision on disciplinary actions. Any such refusal is documented and signed by PSI and the student’s parents. Usually, this is a written final warning prior to expulsion, but may result in expulsion depending on the circumstances of the case (for example, if there has been repeated violation of the substance abuse policy).`}</p>
    <p>{`If the identified circumstances or the condition of the child’s health requires notification of medical authorities, police or embassy (in relation to foreign students), the School takes the appropriate additional measures.`}</p>
    <h4 {...{
      "id": "employees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#employees",
        "aria-label": "employees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Employees`}</h4>
    <p>{`It is important to note that staff handbooks contain similar language for all employees of PSI.`}</p>
    <p><strong parentName="p">{`Bullying`}</strong>{` – See guidelines on page 65.`}</p>
    <p><strong parentName="p">{`Closed Campus`}</strong></p>
    <p>{`All students should carry their swipe cards with them and use them for entering and exiting the campus through the main gates only. Students who lose their cards must replace them at a small cost.`}</p>
    <p>{`Students are not allowed to leave campus and then come back on the same day. `}<strong parentName="p">{`This includes the time between the end of classes and the start of ECAs.`}</strong></p>
    <p>{`Students who leave for the day must complete the permission form and have it signed by all teachers whose classes will be missed as well as by the principal or an IB coordinator.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Dishonesty -`}</em></strong>{` Students who do not respond honestly to teachers or administrators may be subject to disciplinary action, including suspension. This includes telling deliberate falsehoods, hiding information that has been requested, or any other form of deliberate dishonesty.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Displays of Affection`}</em></strong>{` – PSI recognises that general feelings of affection may exist between students. Public displays of affection, however, should not interfere with school life and community expectation. Students should refrain from inappropriate behaviour such as intimate and prolonged embraces and / or kissing, and similar actions on campus, school buses and school events. Repeated incidents will lead to a parent conference.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Dress Code`}</em></strong>{` – Pechersk School International’s student dress code is designed to give students the widest possible range of choices to express themselves through their choice of apparel, whilst recognising that as a diverse community, we must respect each other’s different values and beliefs.`}</p>
    <p>{`A student committee has devised `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1w-1J4yissaiynn3fjWSBFAOW1MO3Yyi1p8OilKclRJQ/edit"
      }}>{` a set of dress code guidelines `}</a>{`; all students are expected to adhere to these expectations.`}</p>
    <p><strong parentName="p">{`*Electronic devices`}</strong>{` –* PSI is a `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1VKjy1GMluBn7rqTsBWWNQs7XA5obg5PF_1U4h-xVPV4/edit"
      }}>{` Bring Your Own Device (BYOD) `}</a>{` school. All Secondary students must have their own laptops. In addition, we allow Secondary students to bring cell-phones, MP3/4 players, tablets and other devices for educational purposes. We also encourage them to bring headphones to school, as these are often used for language activities and multimedia use. Students are expected to use these devices respectfully, following the `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1MWTqF6KnR1Ps9jc_S4jiUqCaogYiy7VHV5qChiYRUYY/edit"
      }}>{` PSI Responsible Use Agreement (RUA) `}</a>{` which is included on MyPSI homeroom sites. This will include following guidelines established by each teacher and includes turning the devices off upon request.The students may not be on gaming sites during instructional time or ECAs. They are not monitored for lunch time, breaks or on the bus. The School does encourage students to engage in games that are age appropriate and nonviolent.`}</p>
    <p>{`Students who inappropriately use electronic devices in class may have those items confiscated. A typical sequence is as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`First offence: Teacher confiscates item and returns it to the student at the end of the class`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Second offence: Teacher confiscates item and turns it over to school leadership; student picks it up at the end of the day`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Third offence: Student turns it over to the principal at the start of a school day for a specified number of days; the phone or device is returned at the end of the day. Parents are contacted.`}</p>
      </li>
    </ul>
    <p><strong parentName="p"><em parentName="strong">{`Fighting`}</em></strong>{` - Any deliberate, aggressive physical contact with another person that might result in an injury is considered fighting. ‘Play fighting’ or ‘horsing around’ can also result in injury and is also prohibited. Fighting will result in a suspension for `}<strong parentName="p">{`all`}</strong>{` students involved. A severe incident and / or repeated incidents may lead to expulsion.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Food and Drink`}</em></strong>{` - Students may have water bottles in school and in classes. Consumption of food in classrooms is by teacher discretion, but students are expected to clean-up after themselves if they bring food into the building. Lunch should be eaten outdoors, in the cafeteria, or in the cafe.`}</p>
    <p>{`Students are not allowed to order food for delivery from outside suppliers without administrative approval. Drivers or other adults bringing students their lunches must take them directly to the designated table in the cafeteria, where students may pick them. Students are then responsible for taking food or food containers home with them.`}</p>
    <p>{`We strongly discourage the use of caffeinated beverages and sugary drinks or food. We ask that students not bring ‘power drinks’ like Red Bull to school.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Inappropriate Behaviour`}</em></strong>{` – Teachers have the right to stop any behaviour that disrupts the learning opportunities or threatens the safety of others.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Insubordination`}</em></strong>{` – Students are expected to follow a staff member's directions.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Laboratory Safety`}</em></strong>{` - Creating an unsafe situation in the Science or Design lab could harm not only the student involved but also others, and will result in disciplinary action.`}</p>
    <p><strong parentName="p">{`Late Work -`}</strong>{` It is important to consistently submit work on the assigned dates. We expect that all students who are absent will contact classmates and / or teachers immediately to determine what work they have missed; it is the student's responsibility, not the teacher's responsibility, to follow up after absences.`}</p>
    <p>{`For more information on attendance and turning in work, please review our attendance protocols on page 23.`}</p>
    <ul>
      <li parentName="ul">{`Grade 6 students * are allowed one opportunity to turn in one late summative assessment, provided that the work is submitted at the date and time established by the teacher. As with the Academic Integrity Policy, parents will be contacted and the student’s name will be entered on a spreadsheet available to all Grade 6 teachers and to school leadership. They will not be given a second opportunity to turn in late work, even if it is for another class.`}</li>
    </ul>
    <p>{`In the case of excused absences (e.g., CEESA, doctor-excused medical absences), teachers will provide revised due dates that are reasonable, given the length of absence involved.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Obscenities and Profanities`}</em></strong>{` - All students are expected to use appropriate, inoffensive language. This also refers to wording and slogans on clothing. Penalties range from parent contact to detention. Language aimed at an individual may be viewed as an act of bullying and could result in suspension. This rule applies to any languages spoken by students.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Stealing`}</em></strong>{` – It is not acceptable for students to take what doesn’t belong to them, even as a practical joke. Students caught stealing are suspended after the first offense and need to return or replace the stolen items. Repeated incidents of stealing and / or the theft of expensive items can result in expulsion.`}</p>
    <p><strong parentName="p">{`Vandalism`}</strong>{` – Destruction or desecration of school or personal property is not allowed. Students who engage in such behaviours are subject to detention or suspension, and will be asked to either reimburse the school or individual and / or assist in the repair or cleaning of damaged items.`}</p>
    <p><strong parentName="p">{`Weapons –`}</strong>{` PSI has a ‘zero tolerance’ policy for weapons on campus. The School defines a weapon as any item that could be used to harm another person. It also includes ‘toy’ weapons. Students caught with a weapon on campus or at a school event are automatically suspended from school. The School reserves the right to move directly to an expulsion hearing if the situation warrants it.`}</p>
    <p><strong parentName="p">{`Note: The school leadership reserves the right to search student lockers, backpacks and / or clothing if they have a suspicion of possession of banned substances, weapons or stolen items.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      